<template>
  <div v-if="permission.access">
    <section>
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <b-form @submit.prevent="Create()">
              <b-row>
                <b-col xl="8">
                  <b-card>
                    <div class="row">
                      <b-col xl="4">
                        <div class="form-group">
                          <label for="Company">Company*</label>
                          <Select
                            filterable
                            style="margin-right: 10px"
                            v-model="form.company_id"
                          >
                            <Option
                              :value="company.id"
                              v-for="(company, index) in companyData"
                              :key="index"
                            >
                              {{ company.name }}
                            </Option>
                          </Select>
                          <has-error
                            :form="form"
                            field="company_id"
                          ></has-error>
                        </div>
                      </b-col>
                      <b-col xl="4">
                        <div class="form-group">
                          <label for="title">Job title*</label>
                          <Input
                            type="text"
                            placeholder="Title"
                            v-model="form.title"
                          ></Input>
                          <has-error :form="form" field="title"></has-error>
                        </div>
                      </b-col>
                      <b-col xl="4">
                        <div class="form-group">
                          <label for="vacancy">Job vacancy*</label>
                          <Input
                            type="number"
                            placeholder="Vacancy"
                            v-model="form.vacancy"
                          ></Input>
                          <has-error :form="form" field="vacancy"></has-error>
                        </div>
                      </b-col>
                      <b-col xl="4">
                        <div class="form-group">
                          <label for="seniority">Seniority*</label>
                          <Select
                            style="margin-right: 10px"
                            v-model="form.seniority"
                          >
                            <Option value="ENTRY"> ENTRY </Option>
                            <Option value="MID"> MID </Option>
                            <Option value="SENIOR"> SENIOR </Option>
                            <Option value="MANAGER"> MANAGER </Option>
                          </Select>
                          <has-error :form="form" field="seniority"></has-error>
                        </div>
                      </b-col>
                      <b-col xl="4">
                        <div class="form-group">
                          <label for="commitment">Commitment*</label>
                          <Select
                            style="margin-right: 10px"
                            v-model="form.commitment"
                          >
                            <Option value="FULLTIME"> FULLTIME </Option>
                            <Option value="PARTTIME"> PARTTIME </Option>
                            <Option value="HOURLY"> HOURLY </Option>
                            <Option value="CONTRACTUAL"> CONTRACTUAL </Option>
                          </Select>
                          <has-error
                            :form="form"
                            field="commitment"
                          ></has-error>
                        </div>
                      </b-col>
                      <b-col xl="4">
                        <div class="form-group">
                          <label for="length">Job duration(if any)*</label>
                          <Input
                            type="text"
                            v-model="form.length"
                            placeholder="(ex : 6 month - 2 years)"
                          ></Input>
                          <has-error :form="form" field="length"></has-error>
                        </div>
                      </b-col>
                      <b-col xl="3">
                        <div class="form-group">
                          <label for="date">Job start date*</label>
                          <div>
                            <DatePicker
                              type="date"
                              placeholder="Select date"
                              class="w-100"
                              v-model="form.estimated_start_date"
                            ></DatePicker>
                          </div>
                          <has-error
                            :form="form"
                            field="estimated_start_date"
                          ></has-error>
                        </div>
                      </b-col>
                      <b-col xl="3">
                        <div class="form-group">
                          <label for="Type">Workplace Type*</label>
                          <Select
                            style="margin-right: 10px"
                            v-model="form.workplace_type"
                          >
                            <Option value="REMOTE"> REMOTE </Option>
                            <Option value="ONSITE"> ONSITE </Option>
                            <Option value="HYBRID"> HYBRID </Option>
                          </Select>
                          <has-error
                            :form="form"
                            field="workplace_type"
                          ></has-error>
                        </div>
                      </b-col>
                      <b-col xl="3">
                        <div class="form-group">
                          <label for="required_experience">Experience* </label>
                          <Input
                            type="number"
                            v-model="form.required_experience"
                            placeholder="Experience"
                          ></Input>
                          <has-error
                            :form="form"
                            field="required_experience"
                          ></has-error>
                        </div>
                      </b-col>
                      <b-col xl="3">
                        <div class="form-group">
                          <label for="deadline">Deadline*</label>
                          <div>
                            <DatePicker
                              type="date"
                              placeholder="Select date"
                              class="w-100"
                              v-model="form.deadline"
                            ></DatePicker>
                          </div>
                          <has-error :form="form" field="deadline"></has-error>
                        </div>
                      </b-col>

                      <b-col xl="12">
                        <div class="form-group">
                          <label for="area">Job summary*</label>
                          <quill-editor
                            v-model="form.summary"
                            style="padding-bottom: 70px; height: 300px"
                          />
                          <has-error :form="form" field="summary"></has-error>
                        </div>
                      </b-col>
                      <b-col xl="6">
                        <b-button
                          variant="primary"
                          type="submit"
                          :disabled="form.busy"
                        >
                          Create
                        </b-button>
                      </b-col>
                      <b-col xl="6">
                        <div class="form-group text-right">
                          <label for="negotiation"> Is Negotiable* &nbsp;</label>
                          <b-form-checkbox
                            name="status"
                            style="margin-top: 10px; padding-top: 4px"
                            v-model="form.negotiable"
                            switch
                            inline
                            id="negotiation"
                          >
                          </b-form-checkbox>
                          <has-error
                            :form="form"
                            field="negotiable"
                          ></has-error></div
                      ></b-col>
                    </div>
                  </b-card>
                </b-col>
                <b-col xl="4">
                  <b-row>
                    <b-col xl="12">
                      <b-card>
                        <b-row>
                          <b-col xl="6">
                            <div class="form-group">
                              <label for="required_experience"
                                >Salary Range (Minimum)*
                              </label>
                              <Input
                                type="number"
                                v-model="form.salary_min"
                                placeholder="Minimum salary"
                              ></Input>
                              <has-error
                                :form="form"
                                field="salary_min"
                              ></has-error>
                            </div>
                          </b-col>
                          <b-col xl="6">
                            <div class="form-group">
                              <label for="required_experience"
                                >Salary Range (Maximum)*
                              </label>
                              <Input
                                type="number"
                                v-model="form.salary_max"
                                placeholder="Maximum salary"
                              ></Input>
                              <has-error
                                :form="form"
                                field="salary_max"
                              ></has-error>
                            </div>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <b-col xl="12">
                      <b-card>
                        <div class="div d-flex justify-content-between">
                          <h5>Select Skills*</h5>
                          <b-button
                            style="padding: 8px 25px !important"
                            variant="primary"
                            @click="addSkill()"
                            class="mb-2 ms-auto"
                          >
                            <feather-icon icon="PlusCircleIcon" size="15" />
                          </b-button>
                        </div>

                        <b-row
                          v-for="(skill, index) in form.skills"
                          :key="index"
                          class="align-items-end mb-1"
                        >
                          <b-col
                            cols="12"
                            xl="6"
                            lg="5"
                            style="min-height: 70px"
                          >
                            <label>Select skill</label>
                            <Select v-model="skill.skill_id" filterable>
                              <Option
                                v-if="filteredSkillOptions(index).length"
                                v-for="item in filteredSkillOptions(index)"
                                :value="item.id"
                                :key="item.id"
                                >{{ item.name }}</Option
                              >
                            </Select>
                            <small
                              v-if="
                                form.errors.has('skills.' + index + '.skill_id')
                              "
                              class="text-danger"
                              >This field is required
                            </small>
                          </b-col>

                          <!-- email -->
                          <b-col
                            cols="12"
                            xl="4"
                            lg="3"
                            style="min-height: 70px"
                          >
                            <label>Type*</label>
                            <Select v-model="skill.type" filterable>
                              <Option :value="1">Required</Option>
                              <Option :value="2">Optional</Option>
                            </Select>
                            <small
                              v-if="
                                form.errors.has('skills.' + index + '.type')
                              "
                              class="text-danger"
                              >This field is required
                            </small>
                          </b-col>

                          <!-- mobile -->

                          <b-col
                            cols="12"
                            xl="2"
                            lg="1"
                            style="min-height: 50px; padding-left: 0"
                          >
                            <b-button
                              variant="danger"
                              @click="removeSkill(index)"
                              style="padding: 8px 25px"
                              class="w-100"
                              :disabled="form.skills.length == 1"
                            >
                              <feather-icon icon="XCircleIcon" size="15" />
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                    <b-col xl="12">
                      <b-card>
                        <QuestionVue :form="form" />
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError } from "vform/src/components/bootstrap5";
import _ from "lodash";
import QuestionVue from "./partials/Question.vue";

export default {
  data() {
    return {
      form: new Form({
        company_id: null,
        title: null,
        vacancy: null,
        seniority: null,
        commitment: null,
        length: null,
        estimated_start_date: null,
        workplace_type: null,
        required_experience: null,
        salary_min: null,
        salary_max: null,
        negotiable: false,
        status: "1",
        summary: null,
        deadline: null,
        skills: [
          {
            skill_id: null,
            type: null,
          },
        ],
        questions: [
          {
            question: null,
            answer_type: null,
          },
        ],
      }),
      showSkillLength: null,
      companyData: [],
      skillsData: [],
    };
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    quillEditor,
    QuestionVue,
  },
  computed: {
    filteredSkillOptions() {
      return (index) => {
        const selectedSkillIds = this.form.skills
          .slice(0, index)
          .map((skill) => skill.skill_id);
        return this.skillsData.filter(
          (skill) => !selectedSkillIds.includes(skill.id)
        );
      };
    },
    permission() {
      return this.$store.getters.getPermissions.jobs;
    },
  },
  mounted() {
    this.getSkill();
    this.getCompany();
  },
  methods: {
    getCompany() {
      axios
        .get("/app/all/company")
        .then((res) => {
          this.companyData = res.data.data;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    getSkill() {
      axios
        .get("/candidate/skills")
        .then((res) => {
          this.skillsData = res.data.skills;
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    Create() {
      this.form
        .post("/app/company/job")
        .then((res) => {
          this.$router.push({ path: "/jobs" });
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },

    addSkill() {
      this.form.skills.push({
        skill_id: null,
        proficiency: null,
        years_of_experience: null,
      });
    },
    removeSkill(index) {
      this.form.skills.splice(index, 1);
    },
  },
};
</script>
